@tailwind base;
@tailwind components;
@tailwind utilities;


h2 {
    font-weight: bold;
    margin: 20px 0 5px;
}

.place-content-center {
    ul {
        list-style: circle;
        padding-left: 10px;

        li {
            margin-left: 10px;
        }
    }
}

.less-expensive {
    position: relative;

    &::before {
        content: attr(data-label);
        display: block;
        transform: rotate(-5deg);
        position: absolute;
        top: 5px;
        left: 5px;
        background: #0568e6;
        border-radius: 5px;
        font-weight: bold;
        text-decoration: underline;
        padding: 5px;
        color: #fff;
        text-shadow: 1px 1px 20px #000000de;
        box-shadow: 1px 1px 5px #000000de;
    }
}

.custom-select {
    position: relative;
    display: inline-block;
    /* width: 100%; */

    select {
        display: none; /* Cache le select natif */
    }

    svg {
        width: 10px;
        margin-left: 5px;
    }
}
  
.select-selected {
    background-color: #f1f1f1;
    border: 1px solid #d4d4d4;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        z-index: 99;
        top: 14px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #888 transparent transparent transparent;
    }
}
  
.select-items {
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid #d4d4d4;
    z-index: 99;
    width: 100%;
    display: none;

    div {
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
            background-color: #f1f1f1;
        }
    }
}
.select-arrow-active {
    &:after {
        border-color: transparent transparent #888 transparent;
    }

    & + .select-items {
        display: block;
    }
}

#mega-menu {

    ul[aria-labelledby="mega-menu-dropdown-button"] {
        li {
            &:last-child {
                margin-bottom: 10px;
            }
        }
    }
}

.pagination {
    .current {
        font-weight: bold;
        font-size: 1.1em;
        color: rgb(30 64 175);
    }
}

ol {
    list-style-type: decimal;
    margin-left: 15px;
    margin-bottom: 15px;

    p {
        margin-bottom: 15px;
    }

    h3 {
        font-weight: bold;
    }
}

.pub-right-col {
    border: 1px solid black;
    width: 300px;
    height: 600px;
}

.products-list {
    .item {
        .most-recents-prices {
            display: none;
            height: calc(168px + 4rem);
            padding: 0 2rem;
            box-sizing: content-box;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        &:hover {
            .main-thumb, .site-name {
                display: none;
            }
            .most-recents-prices {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.search-results .most-recents-prices {
    position: absolute;
    top: 0;
    left: 101%;
    width: 100%;
    z-index: 1;
    background: white;
    height: 100%;
    justify-content: center;

    li {
        margin-bottom: -1px;
        text-align: center;
        padding: 5px 0 10px;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
    }

    img {
        margin: auto;
    }
}